import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import './style.css'
import Home from './views/home'


Sentry.init({
  dsn: "https://e1bc92ef3e2e45e181b959f3a231bff4@o462319.ingest.sentry.io/6524096",
  integrations: [new BrowserTracing()],

  tracesSampleRate: 1.0,
  autoSessionTracking: true,

  environment: process.env.NODE_ENV,

  initialScope: {
    tags: {
      "app": process.env.REACT_APP_NAME,
      "title": process.env.REACT_APP_TITLE
    }
  }

});


const App = () => {
  return (
    <Router>
      <div>
        <Route exact component={Home} path="/" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
